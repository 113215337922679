export default {
    "Activate create/remove space tool": "Attiva lo strumento di creazione/rimozione dello spazio",
    "Activate global connect tool": "Attiva lo strumento di connessione globale",
    "Activate hand tool": "Attiva lo strumento mano",
    "Activate lasso tool": "Attiva lo strumento lazo",
    "Ad-hoc": "Ad hoc",
    "Add lane above": "Aggiungi Corsia sopra",
    "Add lane below": "Aggiungi Corsia sotto",
    "Append compensation activity": "Aggiungi attività di compensazione",
    "Append gateway": "Aggiungi Gateway",
    "Append intermediate/boundary event": "Aggiungi Evento Intermedio/Limite",
    "Business rule task": "Attività di Regole Aziendali",
    "Call activity": "Attività di Chiamata",
    "Cancel boundary event": "Evento di Annullamento del Limite",
    "Cancel end event": "Evento di Annullamento di Fine",
    "Change type": "Cambia tipo",
    "Compensation boundary event": "Evento di Limite di Compensazione",
    "Compensation end event": "Evento di Compensazione di Fine",
    "Compensation intermediate throw event": "Evento di Lancio Intermedio di Compensazione",
    "Compensation start event": "Evento di Inizio di Compensazione",
    "Complex gateway": "Gateway Complesso",
    "Conditional boundary event": "Evento di Limite Condizionale",
    "Conditional boundary event (non-interrupting)": "Evento di Limite Condizionale (senza interruzione)",
    "Conditional intermediate catch event": "Evento di Cattura Intermedia Condizionale",
    "Conditional start event": "Evento di Inizio Condizionale",
    "Conditional start event (non-interrupting)": "Evento di Inizio Condizionale (senza interruzione)",
    "Connect using association": "Connetti tramite Associazione",
    "Connect using data input association": "Connetti utilizzando Associazione Input Dati",
    "Connect using sequence/message flow or association": "Connetti usando Sequenza/FlussodiMessaggi o Associazione",
    "Create expanded sub-process": "Crea processo secondario espanso",
    "Create gateway": "Crea Gateway",
    "Create intermediate/boundary event": "Crea evento di Lancio Intermedio/evento Limite",
    "Create pool/participant": "Crea Pool/Partecipante",
    "Create start event": "Crea evento di Inizio",
    "Divide into three lanes": "Dividi in tre corsie",
    "Divide into two lanes": "Dividi in due corsie",
    "Empty pool/participant": "Pool compresso",
    "End event": "Evento di Fine",
    "Error boundary event": "Evento di Limite di Errore",
    "Error end event": "Evento di Errore di Fine",
    "Error start event": "Evento di Inizio di Errore",
    "Escalation boundary event": "Evento di Limite dell'Escalazione",
    "Escalation boundary event (non-interrupting)": "Evento di limitazione dell'Escalazione (senza interruzione)",
    "Escalation end event": "Evento di Fine dell'Escalazione",
    "Escalation intermediate throw event": "Evento di Lancio Intermedio di Escalazione",
    "Escalation start event": "Evento di Inizio di Escalazione",
    "Escalation start event (non-interrupting)": "Evento di Inizio di Escalazione (senza interruzione)",
    "Event sub-process": "Evento di Processo Secondario",
    "Event-based gateway": "Gateway di Evento",
    "Exclusive gateway": "Gateway Esclusivo",
    "Inclusive gateway": "Gateway Inclusivo",
    "Intermediate throw event": "Evento di Lancio Intermedio",
    "Link intermediate catch event": "Evento di Cattura Intermedia di Link",
    "Link intermediate throw event": "Evento di Lancio Intermedio di Link",
    "Loop": "Ciclo Continuo",
    "Manual task": "Attività Manuale",
    "Message boundary event": "Evento di Limite del Messaggio",
    "Message boundary event (non-interrupting)": "Evento di Limite del Messaggio (senza interruzione)",
    "Message end event": "Evento di Fine del Messaggio",
    "Message intermediate catch event": "Evento di Cattura Intermedia del Messaggio",
    "Message intermediate throw event": "Evento di Lancio Intermedio del Messaggio",
    "Message start event": "Evento di Inizio Messaggio",
    "Message start event (non-interrupting)": "Evento di Inizio del Messaggio (senza interruzione)",
    "Parallel gateway": "Gateway Parallelo",
    "Parallel multi-instance": "Multiistanza Parallela",
    "Receive task": "Ricevi Attività",
    "Remove": "Rimuovi",
    "Script task": "Attività Script",
    "Send task": "Invia Attività",
    "Sequential multi-instance": "Multiistanza Sequenziale",
    "Service task": "Attività di Servizio",
    "Signal boundary event": "Evento di Segnale del Limite",
    "Signal boundary event (non-interrupting)": "Evento di Segnale del Limite (senza interruzione)",
    "Signal end event": "Evento di Segnale di Fine",
    "Signal intermediate catch event": "Evento di Cattura Intermedia del Segnale",
    "Signal intermediate throw event": "Evento di Lancio Intermedio del Segnale",
    "Signal start event": "Evento di Inizio Segnale",
    "Signal start event (non-interrupting)": "Evento di Inizio del Segnale (senza interruzione)",
    "Start event": "Evento di Inizio",
    "Sub-process": "Processo Secondario",
    "Sub-process (collapsed)": "Processo Secondario (compresso)",
    "Task": "Attività",
    "Terminate end event": "Evento di Terminazione di Fine",
    "Timer boundary event": "Evento di Limite del Timer",
    "Timer boundary event (non-interrupting)": "Evento di Limite del Timer (senza interruzione)",
    "Timer intermediate catch event": "Evento di Cattura Intermedia del Timer",
    "Timer start event": "Evento di Inizio Timer",
    "Timer start event (non-interrupting)": "Evento di Inizio del Timer (senza interruzione)",
    "Transaction": "Transazione",
    "User task": "Attività Utente",
    "already rendered {element}": "già reso {element}",
    "diagram not part of bpmn:Definitions": "diagramma non parte di bpmn:Definitions",
    "element required": "elemento richiesto",
    "element {element} referenced by {referenced}#{property} not yet drawn": "element {element} indicato da {referenced}#{property} non ancora disegnato",
    "failed to import {element}": "impossibile importare {element}",
    "flow elements must be children of pools/participants": "Gli elementi di flusso devono essere secondari a pool/partecipanti",
    "more than {count} child lanes": "più di {count} corsie secondarie",
    "no diagram to display": "nessun diagramma da visualizzare",
    "no parent for {element} in {parent}": "nessun genitore per {element} in {parent}",
    "no process or collaboration to display": "nessun processo o collaborazione da visualizzare",
    "no shape type specified": "nessun tipo di forma specificato",
    "out of bounds release": "rilascio fuori limite"
  };
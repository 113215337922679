<div class="flex flex-column w-full h-screen">
    <div class="flex flex-row align-content-center p-3">
        <div class="flex">
            <p-button icon="pi pi-angle-left" [rounded]="true" severity="secondary" [outlined]="true" class="mr-3" (onClick)="back()"></p-button>
        </div>
        <div>
            <h1>{{ businessProcess?.name }}</h1>
        </div>
    </div>
    <p-confirmDialog></p-confirmDialog>

    <div id="edit-business-process-bpmn-container" class="flex w-full h-full">
        <div *ngIf="loading" class="flex justify-content-center absolute w-full h-full z-5">
            <div class="flex align-items-center">
                <p-progressSpinner styleClass="w-4rem h-4rem alpha-spinner block" strokeWidth="8"
                    fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>

        <app-bpmn-editor 
            *ngIf="!loading"
            [xmlContent]="xmlString"
            [businessProcessName]="businessProcessName"
            [organizationId]="organizationId"
            (onSave)="onDiagramSave($event)"
            (onUpdate)="onDiagramUpdate()"
            class="flex w-full h-full"
        >
        </app-bpmn-editor>
    </div>
</div>
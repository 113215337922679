import inherits from 'inherits-browser';
import ElementFactory from 'bpmn-js/lib/features/modeling/ElementFactory';
import { ensureCompatDiRef } from 'bpmn-js/lib/util/CompatibilityUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';
import { assign } from 'min-dash';

export default function CustomElementFactory(bpmnFactory, moddle) {
    ElementFactory.call(this, bpmnFactory, moddle);
    this._bpmnFactory = bpmnFactory;
    this._moddle = moddle;

    this.create = function(elementType, attrs) {

        if (attrs.type === 'aa:Asset') {
            var temp = createAsset(attrs);
            return temp;
        }

        if (attrs.type === 'aa:Document') {
            var temp = createDocument(attrs);
            return temp;
        }

        var element = ElementFactory.prototype.create.call(this, elementType, attrs);

        return element;
    }

    this.createBpmnElement = function(elementType, attrs) {
        return ElementFactory.prototype.createBpmnElement.call(this, elementType, attrs);
    }

    this.createShape = function(attrs) {
        return ElementFactory.prototype.createShape.call(this, attrs);
    }

    const createAsset = (attrs) => {
        attrs = assign({}, attrs || {});
        var size;
        var businessObject = attrs.businessObject,
            di = attrs.di;

        if (!businessObject) {
            if (!attrs.type) { throw new Error('no shape type specified') };
            businessObject = this._bpmnFactory.create(attrs.type);
            ensureCompatDiRef(businessObject);
        }

        if (attrs.params) {
            assign(businessObject, attrs.params);
        }

        if (!isModdleDi(di)) {
            var diAttrs = assign(
              {},
              di || {},
              { id: businessObject.id + '_di' }
            );

            di = this._bpmnFactory.createDiShape(businessObject, diAttrs);
        }

        size = { 
            width: 120, 
            height: Math.max(34, (Math.ceil(businessObject.asset_name.length / Math.floor((120 - 40) / 7)) * 16)) + 4
        };

        attrs = assign({
            id: businessObject.id
        }, attrs, {
            businessObject: businessObject,
            di: di
        }, size);

        return this._baseCreate('shape', attrs);
    }

    const createDocument = (attrs) => {
        attrs = assign({}, attrs || {});
        var size;
        var businessObject = attrs.businessObject,
            di = attrs.di;

        if (!businessObject) {
            if (!attrs.type) { throw new Error('no shape type specified') };
            businessObject = this._bpmnFactory.create(attrs.type);
            ensureCompatDiRef(businessObject);
        }

        if (attrs.params) {
            assign(businessObject, attrs.params);
        }

        if (!isModdleDi(di)) {
            var diAttrs = assign(
              {},
              di || {},
              { id: businessObject.id + '_di' }
            );

            di = this._bpmnFactory.createDiShape(businessObject, diAttrs);
        }

        size = { 
            width: 120, 
            height: Math.max(34, (Math.ceil(businessObject.document_name.length / Math.floor((120 - 40) / 7)) * 16)) + 4
        };

        attrs = assign({
            id: businessObject.id
        }, attrs, {
            businessObject: businessObject,
            di: di
        }, size);

        return this._baseCreate('shape', attrs);
    }

    const isModdleDi = (element) => {
        return isAny(element, [
            'bpmndi:BPMNShape',
            'bpmndi:BPMNEdge',
            'bpmndi:BPMNDiagram',
            'bpmndi:BPMNPlane',
        ]);
    }
}

inherits(CustomElementFactory, ElementFactory);

CustomElementFactory.$inject = [
    'bpmnFactory',
    'moddle'
];
import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';
import { getLabelColor, getRoundRectPath } from 'bpmn-js/lib/draw/BpmnRenderUtil';
import { append as svgAppend, create as svgCreate, classes as svgClasses } from 'tiny-svg';
import { SvgUtil } from './util/svg-util';

const HIGH_PRIORITY = 1500;

export default class CustomRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer, textRenderer) {
    super(eventBus, HIGH_PRIORITY);
    this.eventBus = eventBus;
    this.textRenderer = textRenderer;
    this.bpmnRenderer = bpmnRenderer;
  }

  canRender(element) {
    return element.type === 'aa:Asset' || element.type === 'aa:Document';
  }

  drawShape(parentNode, element) {
    if (element.type === 'aa:Asset') {
      const rect = this.drawRect(parentNode, element.width, element.height, 20, '#D0D0D0', '#fff');
      this.drawCircleAndImage(parentNode, 'assets/primeicons/raw-svg/server.svg');
      this.addText(parentNode, element, element.businessObject.asset_name);
      return rect; 
    } else if (element.type === 'aa:Document') {
      const rect = this.drawRect(parentNode, element.width, element.height, 20, '#D0D0D0', '#fff');
      this.drawCircleAndImage(parentNode, 'assets/primeicons/raw-svg/file.svg');
      this.addText(parentNode, element, element.businessObject.document_name);
      return rect; 
    } else {
        const shape = this.bpmnRenderer.drawShape(parentNode, element);

        return shape;
    }
  }

  drawRect(parentNode, width, height, borderRadius, strokeColor, fillColor) {
    const rect = svgCreate('rect');
    svgAppend(parentNode, rect);

    rect.setAttribute('x', 0);
    rect.setAttribute('y', 0);
    rect.setAttribute('width', width);
    rect.setAttribute('height', height);
    rect.setAttribute('rx', borderRadius);
    rect.setAttribute('ry', borderRadius);
    rect.setAttribute('style', 'stroke-linecap: round; stroke-linejoin: round; stroke:' + strokeColor +'; stroke-width: 2px; fill: ' + fillColor + ';');

    return rect;
  }

  async drawCircleAndImage(parentNode, imgShapePath) {
    const circle = svgCreate('circle');
    svgAppend(parentNode, circle);
    circle.setAttribute( 'cx', 19);
    circle.setAttribute( 'cy', 18);
    circle.setAttribute( 'r', 12);
    circle.setAttribute( 'fill', '#46ABA4');

    const imageContent = await SvgUtil.loadSvgImage(imgShapePath);
    const coloredImage = SvgUtil.changeSvgColor(imageContent, '#fff');
    const image = SvgUtil.renderSvgFromString(coloredImage);

    image.setAttribute('x', 9);
    image.setAttribute('y', 8);
    image.setAttribute('width', 20);
    image.setAttribute('height', 20);


    svgAppend(parentNode, image);
    return circle;
  }

  addText(parentNode, element, textString) {
    var text = this.textRenderer.createText(textString || '', {
      align: 'center-middle',
      box: {
        width: element.width,
        height: element.height
      },
      padding: {top: 7, left: 40, right: 7, bottom: 7  },
      style: {
        fill: getLabelColor(element, this.bpmnRenderer.defaultLabelColor, this.bpmnRenderer.defaultStrokeColor, null),
      }
    });

    svgClasses(text).add('djs-label');
    svgAppend(parentNode, text);

  }

  getShapePath(shape) {
    if (shape.type === 'aa:Asset' || shape.type === 'aa:Document') {
      return getRoundRectPath(shape, 20);
    }

    return this.bpmnRenderer.getShapePath(shape);
  }
}

CustomRenderer.$inject = ['eventBus', 'bpmnRenderer', 'textRenderer'];
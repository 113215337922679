import inherits from 'inherits-browser';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';

var HIGH_PRIORITY = 1500;

/**
 * Specific rules for custom elements
 */
export default function CustomRules(eventBus) {
    RuleProvider.call(this, eventBus);
}

inherits(CustomRules, RuleProvider);

CustomRules.$inject = [ 'eventBus' ];

CustomRules.prototype.init = function() {

    this.addRule('shape.resize', HIGH_PRIORITY, function(context) {
        var shape = context.shape,
            newBounds = context.newBounds;
    
        return canResize(shape, newBounds);
    });

    this.addRule('connection.create', HIGH_PRIORITY, function(context) {
        var source = context.source,
            target = context.target;

        return canConnect(source, target);
    });
    
    this.addRule('connection.reconnectStart', HIGH_PRIORITY, function(context) {
        var connection = context.connection,
            source = context.hover || context.source,
            target = connection.target;
    
        return canConnect(source, target, connection);
    });
    
    this.addRule('connection.reconnectEnd', HIGH_PRIORITY, function(context) {
        var connection = context.connection,
            source = connection.source,
            target = context.hover || context.target;
    
        return canConnect(source, target, connection);
    });

    this.addRule('connection.reconnect', HIGH_PRIORITY, function(context) {
        var connection = context.connection,
            source = connection.source,
            target = context.hover || context.target;
    
        return canConnect(source, target, connection);
    });

    this.addRule('connection.move', HIGH_PRIORITY, function(context) {
        var connection = context.connection,
            source = connection.source,
            target = context.hover || context.target;
    
        return canConnect(source, target, connection);
    });

    this.addRule('connection.layout', HIGH_PRIORITY, function(context) {
        var connection = context.connection,
            source = connection.source,
            target = context.hover || context.target;
    
        return canConnect(source, target, connection);
    });

    this.addRule('connection.updateWaypoints', HIGH_PRIORITY, function(context) {
        var connection = context.connection,
            source = connection.source,
            target = context.hover || context.target;
    
        return canConnect(source, target, connection);
    });
};

CustomRules.prototype.canConnect = canConnect;

CustomRules.prototype.canResize = canResize;

function canResize(shape, newBounds) {
    if (is(shape, 'aa:Asset') || is(shape, 'aa:Document')) {
        return true;
    } else {
        return;
    }
}

function canConnect(source, target, connection) {
    // allow connection between custom shape and task
    if (
        is(target, 'aa:Asset')
        || is(source, 'aa:Asset')
        || is(target, 'aa:Document')
        || is(source, 'aa:Document')
    ) {
        return { type: 'bpmn:Association' };
    } else return;
}

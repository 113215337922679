import inherits from 'inherits-browser';
import Modeling from 'bpmn-js/lib/features/modeling/Modeling';
import { is } from 'bpmn-js/lib/util/ModelUtil';

export default function CustomModeling(
    eventBus,
    elementFactory,
    commandStack,
    bpmnRules
) {
    Modeling.call(this, eventBus, elementFactory, commandStack, bpmnRules);

    this.connect = function(source, target, attrs, hints) {

        var bpmnRules = this._bpmnRules;

        if (!attrs) {
            attrs = bpmnRules.canConnect(source, target);
        }

        if (!attrs) {
            return;
        }

        if (
            is(target, 'aa:Asset')
            || is(source, 'aa:Asset')
            || is(target, 'aa:Document')
            || is(source, 'aa:Document')
        ) {
            attrs.type = 'bpmn:Association';
        }

        return this.createConnection(source, target, attrs, source.parent, hints);
    }
}

inherits(CustomModeling, Modeling);

CustomModeling.$inject = [
  'eventBus',
  'elementFactory',
  'commandStack',
  'bpmnRules'
];
import { some } from 'min-dash';
import inherits from 'inherits-browser';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import AppendShapeHandler from 'diagram-js/lib/features/modeling/cmd/AppendShapeHandler';

export default function CustomAppendShapeHandler(modeling) {
    this._modeling = modeling;
}

inherits(CustomAppendShapeHandler, AppendShapeHandler);

CustomAppendShapeHandler.$inject = [
    'modeling'
];

AppendShapeHandler.prototype.postExecute = function(context) {
    var hints = context.hints || {};

    if (!existsConnection(context.source, context.shape)) {

        // create connection
        if (hints.connectionTarget === context.source) {
            if (
                is(context.source, 'aa:Asset')
                || is(context.shape, 'aa:Asset')
                || is(context.source, 'aa:Document')
                || is(context.shape, 'aa:Document')
            ) {
                this._modeling.connect(context.shape, context.source, { type: 'bpmn:Association' });
            } else {
                this._modeling.connect(context.shape, context.source, context.connection);
            }
        } else {
            if (
                is(context.source, 'aa:Asset')
                || is(context.shape, 'aa:Asset')
                || is(context.source, 'aa:Document')
                || is(context.shape, 'aa:Document')
            ) {
                this._modeling.connect(context.source, context.shape, { type: 'bpmn:Association' });
            } else {
                this._modeling.connect(context.source, context.shape, context.connection);
            }
        }
    }
};

function existsConnection(source, target) {
    return some(source.outgoing, function(c) {
        return c.target === target;
    });
}
import { config } from 'rxjs';

/**
 * This module is an append menu provider for the popup menu.
 */
export default function DocumentPopupProvider(
    config,
    elementFactory,
    popupMenu,
    create,
    autoPlace,
    rules,
    mouse,
    translate,
    modeling
) {

  this._elementFactory = elementFactory;
  this._modeling = modeling;
  this._popupMenu = popupMenu;
  this._create = create;
  this._autoPlace = autoPlace;
  this._rules = rules;
  this._create = create;
  this._mouse = mouse;
  this._translate = translate;
  this._config = config;

  this.register();
}

DocumentPopupProvider.$inject = [
    'config.selectDocument',
    'elementFactory',
    'popupMenu',
    'create',
    'autoPlace',
    'rules',
    'mouse',
    'translate',
    'modeling'
];

/**
 * Register append menu provider in the popup menu
 */
DocumentPopupProvider.prototype.register = function() {
  this._popupMenu.registerProvider('bpmn-select-document', this);
};

DocumentPopupProvider.prototype.open = function(element) {
    this._popupMenu.open.call(element, position, options);
}

/**
 * Gets the append options for the given element as menu entries
 *
 * @param {djs.model.Base} element
 *
 * @return {Array<Object>} a list of menu entry items
 */
DocumentPopupProvider.prototype.getPopupMenuEntries = function(element) {
  const rules = this._rules;
  const translate = this._translate;

  const entries = {};

  this._config.documents.forEach(document => {
    entries[document.id] = {
      label: document.name,
      description: document.description,
      action: this._createEntryAction(element, document)
    };
  });
  
  return entries;

};

/**
 * Create an action for a given target.
 *
 * @param {djs.model.Base} element
 *
 * @return {Object}
 */
DocumentPopupProvider.prototype._createEntryAction = function(element, document) {
  const elementFactory = this._elementFactory;
  const autoPlace = this._autoPlace;
  const create = this._create;
  const mouse = this._mouse;

  const autoPlaceElement = () => {
    const newElement = elementFactory.createShape({ 
      type: 'aa:Document', 
      params: {
        document_id: document.id,
        document_name: document.name
      }
    });
    //create.start(null, newElement, element);
    if (newElement) {
      autoPlace.append(element, newElement);
      /* this._modeling.updateProperties(newElement, {
          asset_id: asset.id,
          asset_name: asset.name
      }); */
    }
    
  };

  return {
    click: autoPlaceElement
  };


};
export default {
    "Activate create/remove space tool": "Activate create/remove space tool",
    "Activate global connect tool": "Activate global connect tool",
    "Activate hand tool": "Activate hand tool",
    "Activate lasso tool": "Activate lasso tool",
    "Ad-hoc": "Ad-hoc",
    "Add lane above": "Add lane above",
    "Add lane below": "Add lane below",
    "Align elements": "Align elements",
    "Align elements bottom": "Align elements bottom",
    "Align elements center": "Align elements center",
    "Align elements left": "Align elements left",
    "Align elements middle": "Align elements middle",
    "Align elements right": "Align elements right",
    "Align elements top": "Align elements top",
    "Append compensation activity": "Append compensation activity",
    "Append conditional intermediate catch event": "Append conditional intermediate catch event",
    "Append end event": "Append end event",
    "Append gateway": "Append gateway",
    "Append intermediate/boundary event": "Append intermediate/boundary event",
    "Append message intermediate catch event": "Append message intermediate catch event",
    "Append receive task": "Append receive task",
    "Append signal intermediate catch event": "Append signal intermediate catch event",
    "Append task": "Append task",
    "Append text annotation": "Append text annotation",
    "Append timer intermediate catch event": "Append timer intermediate catch event",
    "Business rule task": "Business rule task",
    "Call activity": "Call activity",
    "Cancel boundary event": "Cancel boundary event",
    "Cancel end event": "Cancel end event",
    "Change element": "Change element",
    "Change type": "Change type",
    "Collection": "Collection",
    "Compensation boundary event": "Compensation boundary event",
    "Compensation end event": "Compensation end event",
    "Compensation intermediate throw event": "Compensation intermediate throw event",
    "Compensation start event": "Compensation start event",
    "Complex gateway": "Complex gateway",
    "Conditional boundary event": "Conditional boundary event",
    "Conditional boundary event (non-interrupting)": "Conditional boundary event (non-interrupting)",
    "Conditional flow": "Conditional flow",
    "Conditional intermediate catch event": "Conditional intermediate catch event",
    "Conditional start event": "Conditional start event",
    "Conditional start event (non-interrupting)": "Conditional start event (non-interrupting)",
    "Connect using association": "Connect using association",
    "Connect using data input association": "Connect using data input association",
    "Connect using sequence/message flow or association": "Connect using sequence/message flow or association",
    "Create data object reference": "Create data object reference",
    "Create data store reference": "Create data store reference",
    "Create end event": "Create end event",
    "Create expanded sub-process": "Create expanded sub-process",
    "Create gateway": "Create gateway",
    "Create group": "Create group",
    "Create intermediate/boundary event": "Create intermediate/boundary event",
    "Create pool/participant": "Create pool/participant",
    "Create start event": "Create start event",
    "Create task": "Create task",
    "Data object reference": "Data object reference",
    "Data store reference": "Data store reference",
    "Default flow": "Default flow",
    "Distribute elements horizontally": "Distribute elements horizontally",
    "Distribute elements vertically": "Distribute elements vertically",
    "Divide into three lanes": "Divide into three lanes",
    "Divide into two lanes": "Divide into two lanes",
    "Empty pool/participant": "Empty pool/participant",
    "Empty pool/participant (removes content)": "Empty pool/participant (removes content)",
    "End event": "End event",
    "Error boundary event": "Error boundary event",
    "Error end event": "Error end event",
    "Error start event": "Error start event",
    "Escalation boundary event": "Escalation boundary event",
    "Escalation boundary event (non-interrupting)": "Escalation boundary event (non-interrupting)",
    "Escalation end event": "Escalation end event",
    "Escalation intermediate throw event": "Escalation intermediate throw event",
    "Escalation start event": "Escalation start event",
    "Escalation start event (non-interrupting)": "Escalation start event (non-interrupting)",
    "Event sub-process": "Event sub-process",
    "Event-based gateway": "Event-based gateway",
    "Exclusive gateway": "Exclusive gateway",
    "Inclusive gateway": "Inclusive gateway",
    "Intermediate throw event": "Intermediate throw event",
    "Link intermediate catch event": "Link intermediate catch event",
    "Link intermediate throw event": "Link intermediate throw event",
    "Loop": "Loop",
    "Manual task": "Manual task",
    "Message boundary event": "Message boundary event",
    "Message boundary event (non-interrupting)": "Message boundary event (non-interrupting)",
    "Message end event": "Message end event",
    "Message intermediate catch event": "Message intermediate catch event",
    "Message intermediate throw event": "Message intermediate throw event",
    "Message start event": "Message start event",
    "Message start event (non-interrupting)": "Message start event (non-interrupting)",
    "Parallel gateway": "Parallel gateway",
    "Parallel multi-instance": "Parallel multi-instance",
    "Participant multiplicity": "Participant multiplicity",
    "Receive task": "Receive task",
    "Remove": "Remove",
    "Script task": "Script task",
    "Send task": "Send task",
    "Sequence flow": "Sequence flow",
    "Sequential multi-instance": "Sequential multi-instance",
    "Service task": "Service task",
    "Signal boundary event": "Signal boundary event",
    "Signal boundary event (non-interrupting)": "Signal boundary event (non-interrupting)",
    "Signal end event": "Signal end event",
    "Signal intermediate catch event": "Signal intermediate catch event",
    "Signal intermediate throw event": "Signal intermediate throw event",
    "Signal start event": "Signal start event",
    "Signal start event (non-interrupting)": "Signal start event (non-interrupting)",
    "Start event": "Start event",
    "Sub-process": "Sub-process",
    "Sub-process (collapsed)": "Sub-process (collapsed)",
    "Sub-process (expanded)": "Sub-process (expanded)",
    "Task": "Task",
    "Terminate end event": "Terminate end event",
    "Timer boundary event": "Timer boundary event",
    "Timer boundary event (non-interrupting)": "Timer boundary event (non-interrupting)",
    "Timer intermediate catch event": "Timer intermediate catch event",
    "Timer start event": "Timer start event",
    "Timer start event (non-interrupting)": "Timer start event (non-interrupting)",
    "Toggle non-interrupting": "Toggle non-interrupting",
    "Transaction": "Transaction",
    "User task": "User task",
    "already rendered {element}": "already rendered {element}",
    "correcting missing bpmnElement on {plane} to {rootElement}": "correcting missing bpmnElement on {plane} to {rootElement}",
    "diagram not part of bpmn:Definitions": "diagram not part of bpmn:Definitions",
    "element required": "element required",
    "element {element} referenced by {referenced}#{property} not yet drawn": "element {element} referenced by {referenced}#{property} not yet drawn",
    "failed to import {element}": "failed to import {element}",
    "flow elements must be children of pools/participants": "flow elements must be children of pools/participants",
    "missing {semantic}#attachedToRef": "missing {semantic}#attachedToRef",
    "more than {count} child lanes": "more than {count} child lanes",
    "multiple DI elements defined for {element}": "multiple DI elements defined for {element}",
    "no bpmnElement referenced in {element}": "no bpmnElement referenced in {element}",
    "no diagram to display": "no diagram to display",
    "no parent for {element} in {parent}": "no parent for {element} in {parent}",
    "no plane for {element}": "no plane for {element}",
    "no process or collaboration to display": "no process or collaboration to display",
    "no shape type specified": "no shape type specified",
    "out of bounds release": "out of bounds release",
    "unknown di {di} for element {semantic}": "unknown di {di} for element {semantic}",
    "unrecognized flowElement {element} in context {context}": "unrecognized flowElement {element} in context {context}",
    "unsupported bpmnElement for {plane}: {rootElement}": "unsupported bpmnElement for {plane}: {rootElement}",
    "{semantic}#{side} Ref not specified": "{semantic}#{side} Ref not specified"
  };
import inherits from 'inherits-browser';
import OutlineProvider from 'bpmn-js/lib/features/outline/OutlineProvider';
import { append as svgAppend, attr as svgAttr, create as svgCreate, remove as svgRemove } from 'tiny-svg';

export default function CustomOutlineProvider(outline, eventBus, canvas, styles) {
    this._styles = styles;
    outline.registerProvider(this);

    this.createOutline = function(parent, bounds, attrs) {
        const outline = svgCreate('rect');
    
        svgAttr(outline, {
          ...attrs,
          x: bounds.x,
          y: bounds.y,
          width: bounds.width,
          height: bounds.height,
          rx: attrs.rx || 14, // Rounded corner radius
          ry: attrs.ry || 14  // Rounded corner radius
        });
    
        svgAppend(parent, outline);
    
        return outline;
    };

    this.updateShapeOutline = function(parent, element) {
        // Remove existing outline
        svgRemove(parent.querySelector('.djs-outline'));
    
        const { width, height, x, y } = element;
    
        const outlineAttrs = {
          class: 'djs-outline'
        };
    
        if (element.type === 'aa:Asset' || element.type === 'aa:Document') {
          // Use custom outline attributes or logic here
          outlineAttrs.rx = 20; // Rounded corners
          outlineAttrs.ry = 20; // Rounded corners
        }
    
        const bounds = {
          x: - 5,
          y: - 5,
          width: width + 10,
          height: height + 10
        };
    
        this.createOutline(parent, bounds, outlineAttrs);
    };
    
    // Listen for selection changes to apply the custom outline
    eventBus.on('selection.changed', (event) => {
        const { newSelection } = event;

        newSelection.forEach((element) => {
            const gfx = canvas.getGraphics(element);
            this.updateShapeOutline(gfx, element);
        });
    });
}

inherits(CustomOutlineProvider, OutlineProvider);

CustomOutlineProvider.$inject = ['outline', 'eventBus', 'canvas', 'styles'];
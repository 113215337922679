import inherits from 'inherits-browser';
import AutoPlace from 'bpmn-js/lib/features/auto-place/BpmnAutoPlace';

export default function CustomAutoPlace(eventBus, modeling, canvas) {
    AutoPlace.call(this, eventBus);
    this._eventBus = eventBus;

    eventBus.on('autoPlace', function(context) {
        var shape = context.shape,
            source = context.source;

        var shapePosition = getNewShapePosition(source, shape);

        return shapePosition;
    });

    /**
     * Append shape to source at appropriate position.
     *
     * @param {Shape} source
     * @param {Shape} shape
     * @param {any} [hints={}]
     *
     * @return {Shape} appended shape
     */
    this.append = function(source, shape, hints) {

        eventBus.fire('autoPlace.start', {
            source: source,
            shape: shape
        });

        // allow others to provide the position
        var position = eventBus.fire('autoPlace', {
            source: source,
            shape: shape
        });

        var newShape = modeling.appendShape(source, shape, position, source.parent, hints);
        eventBus.fire('autoPlace.end', {
            source: source,
            shape: newShape
        });

        return newShape;
    };
}

inherits(CustomAutoPlace, AutoPlace);

CustomAutoPlace.$inject = ['eventBus', 'modeling', 'canvas'];
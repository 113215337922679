
export default function ContextPadProvider(
    config,
    contextPad,
    create,
    elementFactory,
    injector,
    translate,
    popupMenu
) {
    this._create = create;
    this._elementFactory = elementFactory;
    this._translate = translate;
    this._popupMenu = popupMenu;
    this._contextPad = contextPad;
    if (config.autoPlace !== false) {
        this._autoPlace = injector.get('autoPlace', false);
    }
    this.register();
}

ContextPadProvider.$inject = [
    'config',
    'contextPad',
    'create',
    'elementFactory',
    'injector',
    'translate',
    'popupMenu'
];

ContextPadProvider.prototype.register = function() {
    this._contextPad.registerProvider(this);
};

ContextPadProvider.prototype.getContextPadEntries = function(element) {

    const linkAsset = (event, element) => {
        const position = getMenuPosition(element);
        this._popupMenu.open(element, 'bpmn-select-asset', position, {
            title: this._translate('Select Asset'),
            width: 300,
            search: true
        });
    };

    const linkDocument = (event, element) => {
        const position = getMenuPosition(element);
        this._popupMenu.open(element, 'bpmn-select-document', position, {
            title: this._translate('Select Document'),
            width: 300,
            search: true
        });
    };

    const goToAsset = (event, element) => {
        const assetId = element.businessObject.asset_id;
        var url = window.location.origin + window.location.pathname;
        url = url.substring(0, url.indexOf('business-processes'));
        url += 'assets/' + assetId + '/edit';
        window.open(url, '_blank').focus();
    }

    const goToDocument = (event, element) => {
        const documentId = element.businessObject.document_id;
        var url = window.location.origin + window.location.pathname;
        url = url.substring(0, url.indexOf('business-processes'));
        url += 'documents/' + documentId + '/edit';
        window.open(url, '_blank').focus();
    }
    
    /**
     * Calculates the position for the append menu relatively to the element
     *
     * @param {djs.model.Base} element
     * @returns {Object}
     */
    const getMenuPosition = (element) => {
        const X_OFFSET = 5;
        const pad = this._contextPad.getPad(element).html;
        const padRect = pad.getBoundingClientRect();
        const pos = {
            x: padRect.right + X_OFFSET,
            y: padRect.top
        };
        return pos;
    };

    return function(entries) {
        if (
            element.type === 'bpmn:Task'
            || element.type === 'bpmn:UserTask'
            || element.type === 'bpmn:ServiceTask'
            || element.type === 'bpmn:BusinessRuleTask'
            || element.type === 'bpmn:ScriptTask'
        ) {
            entries['append.link-asset'] = 
                {
                    group: 'custom',
                    className: 'pi pi-server',
                    title: this._translate('Link Asset'),
                    action: {
                        click: linkAsset
                    }
                };
            entries['append.link-document'] = 
                {
                    group: 'custom',
                    className: 'pi pi-file-plus',
                    title: this._translate('Link Document'),
                    action: {
                        click: linkDocument
                    }
                };
        } else if (element.type === 'aa:Asset' || element.type === 'aa:Document') {
            
            if (element.type === 'aa:Asset') {
                entries['append.go-to-asset'] = 
                {
                    group: 'custom',
                    className: 'pi pi-external-link',
                    title: this._translate('Go to Asset'),
                    action: {
                        click: goToAsset
                    }
                };
            } else {
                entries['append.go-to-document'] = 
                    {
                        group: 'custom',
                        className: 'pi pi-external-link',
                        title: this._translate('Go to Document'),
                        action: {
                            click: goToDocument
                        }
                    };
            }

            if (entries['set-color']) {
                delete entries['set-color'];
            }
            if (entries['append']) {
            delete entries['append'];
            }
            delete entries["connect"];
            delete entries["replace"];
            delete entries["append.append-task"];
            delete entries["append.end-event"];
            delete entries["append.gateway"];
            delete entries["append.intermediate-event"];
            delete entries["append.text-annotation"];
        }
        return entries;
    }.bind(this);
}


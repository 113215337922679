import it from './translations/it';
import en from './translations/en';


export default function customTranslate(language) {
    if (language === 'it') {
        return function(template, replacements) {
            replacements = replacements || {};
    
            // Translate
            template = it[template] || template;
    
            // Replace
            return template.replace(/{([^}]+)}/g, function(_, key) {
                return replacements[key] || '{' + key + '}';
            });
        }
    } else {
        return function(template, replacements) {
            replacements = replacements || {};
    
            // Translate
            template = en[template] || template;
    
            // Replace
            return template.replace(/{([^}]+)}/g, function(_, key) {
                return replacements[key] || '{' + key + '}';
            });
        }
    }
}
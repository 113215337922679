import AssetBehavior from './asset-behavior';
import AssetPopupProvider from './asset-popup-provider';
import ContextPadProvider from './context-pad-provider';
import CustomAppendShapeHandler from './custom-append-shape-handler';
import CustomAutoPlace from './custom-autoplace';
import CustomElementFactory from './custom-element-factory';
import CustomGridSnappingAutoPlaceBehavior from './custom-grid-snapping-auto-place-behavior';
import CustomModeling from './custom-modeling';
import CustomOutlineProvider from './custom-outline-provider';
import CustomRenderer from './custom-renderer';
import CustomRules from './custom-rules';
import DocumentBehavior from './document-behavior';
import DocumentPopupProvider from './document-popup-provider';

export default {
  __init__: [ 
    'elementFactory',
    'autoPlace',
    'linkAssetPopupProvider',
    'linkDocumentPopupProvider',
    'customContextPad',
    'customRenderer',
    'modeling',
    'customRules',
    'appendShapeHandler',
    'gridSnappingAutoPlaceBehavior',
    'assetBehavior',
    'documentBehavior',
    'outlineProvider'
  ],
  
  elementFactory: [ 'type', CustomElementFactory],
  autoPlace: [ 'type', CustomAutoPlace ],
  linkAssetPopupProvider: [ 'type', AssetPopupProvider ],
  linkDocumentPopupProvider: [ 'type', DocumentPopupProvider ],
  customContextPad: [ 'type', ContextPadProvider ],
  customRenderer: [ 'type', CustomRenderer ],
  modeling: [ 'type', CustomModeling ],
  customRules: [ 'type', CustomRules ],
  appendShapeHandler: [ 'type', CustomAppendShapeHandler ],
  gridSnappingAutoPlaceBehavior: [ 'type', CustomGridSnappingAutoPlaceBehavior ],
  assetBehavior: [ 'type', AssetBehavior ],
  documentBehavior: [ 'type', DocumentBehavior ],
  outlineProvider: [ 'type', CustomOutlineProvider ]
};
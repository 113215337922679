import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  output
} from '@angular/core';
import { } from 'diagram-js/lib/core/Types'; 
import { Element } from 'bpmn-js/lib/model/Types';
import Modeler from 'bpmn-js/lib/Modeler';
import GridModule from 'diagram-js-grid';
import ColorPickerModule from 'bpmn-js-color-picker';
import TaskResizeModule from 'bpmn-js-task-resize';
import AlphaCustomModule from './modules';
import { CreateAppendAnythingModule } from 'bpmn-js-create-append-anything';
import AddExporterModule from '@bpmn-io/add-exporter';
import customTranslate from './modules/custom-translate';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import type Canvas from 'diagram-js/lib/core/Canvas';
import type ZoomScroll from 'diagram-js/lib/navigation/zoomscroll/ZoomScroll';
import type ElementRegistry from 'diagram-js/lib/core/ElementRegistry';
import type Modeling from 'bpmn-js/lib/features/modeling/Modeling';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { AssetService } from 'src/app/service/api/asset.service';
import { Asset } from 'src/app/model/asset.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DocumentService } from 'src/app/service/api/document.service';
import { Document } from 'src/app/model/document.model';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EventBus } from 'bpmn-js/lib/BaseViewer';


@Component({
  selector: 'app-bpmn-editor',
  standalone: true,
  imports: [
    ButtonModule,
    TooltipModule,
    DialogModule,
    FontAwesomeModule,
    FileUploadModule,
    ConfirmDialogModule,
    TranslateModule
  ],
  templateUrl: './bpmn-editor.component.html',
  styleUrl: './bpmn-editor.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, ConfirmationService]
})
export class BpmnEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() xmlContent: string | null = null;
  @Input() organizationId: string | null = null;
  @Input() businessProcessName?: string | null = null;
  onSave = output<string | undefined>();
  onUpdate = output();
  @ViewChild('canvas', { static: true }) private canvas!: ElementRef;
  
  assets: Asset[] | null = null;
  documents: Document[] | null = null;
  showShortcut: boolean = false;
  private bpmnModeler?: Modeler;
  private modelHasChanges: boolean = false;


  customTypesExtension = {
    "name": "AlphaCustomTypesExtension",
    "uri": "http://alpha/schema/bpmn/custom-types-extension",
    "prefix": "aa",
    "xml": {
      "tagAlias": "lowerCase"
    },
    "types": [
      {
        "name": "Asset",
        "superClass": [
          "bpmn:FlowNode",
          "bpmn:BaseElement"
        ],
        "properties": [
          {
            "name": "asset_id",
            "isAttr": true,
            "type": "String"
          },
          {
            "name": "asset_name",
            "isAttr": true,
            "type": "String"
          }
        ]
      },
      {
        "name": "Document",
        "superClass": [
          "bpmn:FlowNode",
          "bpmn:BaseElement"
        ],
        "properties": [
          {
            "name": "document_id",
            "isAttr": true,
            "type": "String"
          },
          {
            "name": "document_name",
            "isAttr": true,
            "type": "String"
          }
        ]
      }
    ]
  };

  newDiagramXML = `
    <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn" exporter="bpmn-js" exporterVersion="17.6.4">
    <bpmn:process id="Process_1" isExecutable="false">
    <bpmn:startEvent id="StartEvent_1"/>
    </bpmn:process>
    <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
    <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
    <dc:Bounds x="173" y="102" width="36" height="36"/>
    </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
    </bpmndi:BPMNDiagram>
    </bpmn:definitions>
  `;

  constructor(
    private library: FaIconLibrary,
    private assetService: AssetService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private logger: NGXLogger
  ) {
    library.addIcons(faKeyboard);
    this.organizationId = route.snapshot.queryParamMap.get('organizationId');
  }

  async getDocuments() {
    try {
      const documentResponse = await lastValueFrom(this.documentService.list(this.organizationId!));
      this.documents = documentResponse.data;
    } catch(e) {
    }
  }

  async getAssets() {
    try {
      const assetsResponse = await lastValueFrom(this.assetService.list(this.organizationId!));
      this.assets = assetsResponse.data;
      var customTranslateModule = {
        translate: [ 'value', customTranslate(this.translate.currentLang) ]
      };
      
      this.bpmnModeler = new Modeler({
        keyboard: {
          bindTo: window
        },
        additionalModules: [
          CreateAppendAnythingModule,
          GridModule,
          AddExporterModule,
          ColorPickerModule,
          TaskResizeModule,
          customTranslateModule,
          AlphaCustomModule
        ],
        taskResizingEnabled: true,
        exporter: {
          name: 'bpmn-js',
          version: '17.6.4'
        },
        selectAsset: { assets: this.assets },
        selectDocument: {documents: this.documents},
        moddleExtensions: {
          customTypesExtension: this.customTypesExtension
        }
      });

      if (this.xmlContent != null) {
        await this.bpmnModeler?.importXML(this.xmlContent);
        await this.checkUpdates();
      } else {
        this.bpmnModeler?.createDiagram();
      }
  
      this.bpmnModeler?.attachTo(this.canvas.nativeElement);

      const eventBus: EventBus<any> = this.bpmnModeler.get('eventBus');

      eventBus.on('commandStack.changed', (event) => {
        this.modelHasChanges = true;
        this.onUpdate.emit();
      });
      
    } catch(e) {
      this.logger.error(`[${this.constructor.name}] ERROR loading BPMN editor: ${JSON.stringify(e)}`);
    }
  }

  async ngOnInit(): Promise<void> {
    this.updateProcessName();
    await this.getDocuments();
    await this.getAssets();

    const existingAnchor = this.canvas.nativeElement.querySelector('.bjs-powered-by');
    
    if (existingAnchor) {
  
      existingAnchor.onclick = (event: MouseEvent) => {

        const existingLightbox = document.body.querySelector('.bjs-powered-by-lightbox');         
        if (existingLightbox) {
          existingLightbox.remove()
        }
                
        this.bpmnClicked(); 
      };
    }
  }

  ngAfterViewInit(): void {
    
  }

  ngOnDestroy(): void {
    this.bpmnModeler?.destroy();
  }

  updateProcessName() {
    if (this.xmlContent == null) {
      this.xmlContent = this.newDiagramXML;
    }

    if (this.businessProcessName != null) {
      const sanitizedProcessName = this.businessProcessName.replaceAll(' ', '_');
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(this.xmlContent, 'application/xml');

      const processElement = xmlDoc.getElementsByTagName('bpmn:process')[0];

      if (processElement) {
        const processElementId = processElement.getAttribute('id');

        processElement.setAttribute('name', this.businessProcessName);

        const serializer = new XMLSerializer();
        this.xmlContent = serializer.serializeToString(xmlDoc);
      } else {
        this.logger.error(`[${this.constructor.name}] ERROR updating process name: bpmn:process element not found.`);
      }
    }
  }

  async checkUpdates() {
    const elementRegistry = this.bpmnModeler!.get<ElementRegistry>('elementRegistry');
    const modeling = this.bpmnModeler!.get<Modeling>('modeling');
    const assetElements = await elementRegistry.filter((element) => {
      return is(element, 'aa:Asset');
    });
    const documentElements = await elementRegistry.filter((element) => {
      return is(element, 'aa:Document');
    });

    const assetElementsToRemove: Element[] = [];
    const documentElementsToRemove: Element[] = [];

    for (var assetElement of assetElements) {
      if (!this.assets?.find( o => o.id === assetElement.businessObject.asset_id)) {
        //@ts-ignore
        assetElementsToRemove.push(assetElement);
      } else if (
        this.assets?.find( o => o.id === assetElement.businessObject.asset_id)
        && !this.assets?.find( o => o.name === assetElement.businessObject.asset_name)) {
          //@ts-ignore
          modeling.updateProperties (assetElement, {
            asset_name : this.assets.find(o => o.id === assetElement.businessObject.asset_id)?.name
          })
      }
    }

    for (var documentElement of documentElements) {
      if (!this.documents?.find( o => o.id === documentElement.businessObject.document_id)) {
        //@ts-ignore
        documentElementsToRemove.push(documentElement);
      } else if (
        this.documents?.find( o => o.id === documentElement.businessObject.document_id)
        && !this.documents?.find( o => o.name === documentElement.businessObject.document_name)) {
          //@ts-ignore
          modeling.updateProperties (documentElement, {
            document_name : this.documents.find(o => o.id === documentElement.businessObject.document_id)?.name
          })
      }
    }

    let elementsRemoved = '';

    if (assetElementsToRemove.length > 0 && documentElementsToRemove.length > 0) {
      elementsRemoved = 'MESSAGES.assets-and-documents-removed';
    }

    if (assetElementsToRemove.length > 0 && documentElementsToRemove.length == 0) {
      elementsRemoved = 'MESSAGES.assets-removed';
    }

    if (assetElementsToRemove.length == 0 && documentElementsToRemove.length > 0) {
      elementsRemoved = 'MESSAGES.documents-removed';
    }

    if (elementsRemoved != '') {
      this.confirmationService.confirm({
        header: this.translate.instant('LABELS.warning'),
        message: this.translate.instant(elementsRemoved),
        icon:'pi pi-exclamation-triangle',
        rejectVisible: false,
        acceptIcon: 'none',
        acceptButtonStyleClass: 'p-button-text',
        acceptLabel: this.translate.instant('LABELS.ok')
      });
      modeling.removeElements(documentElementsToRemove);
    }
  }

  onResize() {
    if (this.bpmnModeler != null) {
      this.bpmnModeler.get<Canvas>('canvas').resized();
    }
  }

  showShortCut() {
    this.showShortcut = true;
  }

  zoomReset() {
    this.bpmnModeler?.get<ZoomScroll>('zoomScroll').reset();
  }

  zoomIn() {
    this.bpmnModeler?.get<ZoomScroll>('zoomScroll').stepZoom(1);
  }

  zoomOut() {
    this.bpmnModeler?.get<ZoomScroll>('zoomScroll').stepZoom(-1);
  }

  async save() {
    const diagramXML = await this.bpmnModeler?.saveXML();
    this.onSave.emit(diagramXML?.xml);
  }

  async import(event: FileSelectEvent) {
    if ('currentFiles' in event) {      
      const selectedXml = await event.currentFiles[0].text();      
      this.bpmnModeler?.importXML(selectedXml);
      this.onUpdate.emit();
    }
  }

  async export() {
    const diagramXML = await this.bpmnModeler?.saveXML();
    if (diagramXML?.xml != null) {
      const bmpnBlob = new Blob([diagramXML.xml], {type: 'application/xml' });
      const bmpnBlobFile = new File([bmpnBlob], 'diagram.xml', {type: 'application/xml' });
      let url = window.URL.createObjectURL(bmpnBlobFile);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'model.xml');
      link.click();
      window.URL.revokeObjectURL(url);
    } else {
      this.logger.error(`[${this.constructor.name}] Error exporting xml`)
    }

  }

  async downloadSVG() {
    let updatedXML = await this.bpmnModeler?.saveXML();
    if (updatedXML != null && updatedXML.xml != null) {
      await this.bpmnModeler?.importXML(updatedXML.xml);
      let diagramSVG = await this.bpmnModeler?.saveSVG();
      if (diagramSVG != null) {
        const svgBlob = new Blob([diagramSVG.svg], { type: 'image/svg+xml' });
        let url = window.URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.setAttribute('download', 'model.svg')
        downloadLink.click();
        window.URL.revokeObjectURL(url);
      } else {
        this.logger.error(`[${this.constructor.name}] Error saving svg`)
      }
    }
    

  }

  bpmnClicked() {
    window.open('https://bpmn.io');
  }
  
}

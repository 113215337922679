import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { } from 'bpmn-js/lib/model/Types';
import { } from 'diagram-js/lib/core/Types';
import { NGXLogger } from 'ngx-logger';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { BaseFormComponent } from 'src/app/components/base-form/base-form.component';
import { BusinessProcess } from 'src/app/model/business-process.model';
import { BusinessProcessesService } from 'src/app/service/api/business-processes.service';
import { ToastService } from 'src/app/service/utils/toast.service';



@Component({
  selector: 'app-business-processes-bpmn',
  templateUrl: './business-processes-bpmn.component.html',
  styleUrl: './business-processes-bpmn.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService]
})
export class BusinessProcessesBPMNComponent extends BaseFormComponent implements OnInit, AfterContentInit {

  loading: boolean = false;
  xmlString: string | null = null;
  organizationId: string | null = null;
  businessProcess?: BusinessProcess | null = null;
  businessProcessName?: string | null = null;
  businessProcessId?: string | null = null;
  diagramHasChanges: boolean = false;

  constructor(
    private businessProcessService: BusinessProcessesService,
    private messageService: MessageService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: NGXLogger,
    public translate: TranslateService
  ) {
    super();
    this.organizationId = route.snapshot.paramMap.get('organizationId');
    this.businessProcessId = route.snapshot.paramMap.get('businessProcessId');
  }

  ngOnInit(): void {
    this.getBusinessProcess();
  }

  override canDeactivate(): boolean {    
    return !this.diagramHasChanges;
  }

  ngAfterContentInit(): void {
    this.getBusinessProcess();
    this.getBpmnDiagramXmlString();
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  async getBusinessProcess() {
    this.loading = true;
    if (this.organizationId != null && this.businessProcessId != null) {
      try {
        const businessProcessResponse = await lastValueFrom(this.businessProcessService.get(this.organizationId, this.businessProcessId));
        this.businessProcess = businessProcessResponse.data;       
      } catch (e) {
        if (e instanceof HttpErrorResponse) {
        }
        this.logger.error(`${this.constructor.name} Error retriving Business Process: ` + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

  async getBpmnDiagramXmlString() {
    try {
      this.loading = true;
      const businessProcessResponse = await lastValueFrom(this.businessProcessService.getDiagramFile(this.organizationId!, this.businessProcessId!));

      this.xmlString = await businessProcessResponse.text(); 
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if(e.status === 500) {
          this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.unknown') });
        }
      }
      this.logger.error(`[${this.constructor.name}] Error retriving bmpn diagram file:` + JSON.stringify(e));      
    }
    this.loading = false;
  }
  
  async onDiagramSave(xmlDiagram: string | undefined) {
    this.loading = true;
    if (this.organizationId != null) {

      let file = undefined;

      if (xmlDiagram != null) {
        const bmpnBlob = new Blob([xmlDiagram], {type: 'application/xml' });
        file = new File([bmpnBlob], 'diagram.xml', {type: 'application/xml' });
      }
      
      try {
        const businessProcessResponse = await lastValueFrom(
          this.businessProcessService.update(
            this.organizationId,
            this.businessProcess!,
            file
          )
        );
        this.businessProcess = businessProcessResponse.data;
        this.diagramHasChanges = false;
        this.toastService.showSuccess(this.translate.instant('MESSAGES.business-process-updated'));
        this.back();
      } catch (e) {
        this.messageService.clear();
        if (e instanceof HttpErrorResponse) {
          if (e.status === 422) {
            this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.input-validation-error') });
          } else {
            this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.unknown') });
          }
        } else {
          this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.unknown') });
        }
        
        this.logger.error(`[${this.constructor.name}] Error updating business file: ` + JSON.stringify(e));
      }
    }
    this.loading = false;
  }

  onDiagramUpdate() {
    this.diagramHasChanges = true;
  }
  
}

import inherits from 'inherits-browser';
import Viewer from './Viewer';
import KeyboardMoveModule from 'diagram-js/lib/navigation/keyboard-move';
import MoveCanvasModule from 'diagram-js/lib/navigation/movecanvas';
import ZoomScrollModule from 'diagram-js/lib/navigation/zoomscroll';

/**
 * @typedef { import('./BaseViewer').BaseViewerOptions } BaseViewerOptions
 */

/**
 * A viewer with mouse and keyboard navigation features.
 *
 * @template [ServiceMap=null]
 *
 * @extends Viewer<ServiceMap>
 *
 * @param {BaseViewerOptions} [options]
 */
export default function NavigatedViewer(options) {
  Viewer.call(this, options);
}
inherits(NavigatedViewer, Viewer);
NavigatedViewer.prototype._navigationModules = [KeyboardMoveModule, MoveCanvasModule, ZoomScrollModule];
NavigatedViewer.prototype._modules = [].concat(Viewer.prototype._modules, NavigatedViewer.prototype._navigationModules);
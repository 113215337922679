/**
 * This file must not be changed or exchanged.
 *
 * @see http://bpmn.io/license for more information.
 */

import { assignStyle, domify, delegate as domDelegate, query as domQuery } from 'min-dom';

// inlined ../../resources/logo.svg
var BPMNIO_LOGO_SVG = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.02 5.57" width="53" height="21"><path fill="currentColor" d="M1.88.92v.14c0 .41-.13.68-.4.8.33.14.46.44.46.86v.33c0 .61-.33.95-.95.95H0V0h.95c.65 0 .93.3.93.92zM.63.57v1.06h.24c.24 0 .38-.1.38-.43V.98c0-.28-.1-.4-.32-.4zm0 1.63v1.22h.36c.2 0 .32-.1.32-.39v-.35c0-.37-.12-.48-.4-.48H.63zM4.18.99v.52c0 .64-.31.98-.94.98h-.3V4h-.62V0h.92c.63 0 .94.35.94.99zM2.94.57v1.35h.3c.2 0 .3-.09.3-.37v-.6c0-.29-.1-.38-.3-.38h-.3zm2.89 2.27L6.25 0h.88v4h-.6V1.12L6.1 3.99h-.6l-.46-2.82v2.82h-.55V0h.87zM8.14 1.1V4h-.56V0h.79L9 2.4V0h.56v4h-.64zm2.49 2.29v.6h-.6v-.6zM12.12 1c0-.63.33-1 .95-1 .61 0 .95.37.95 1v2.04c0 .64-.34 1-.95 1-.62 0-.95-.37-.95-1zm.62 2.08c0 .28.13.39.33.39s.32-.1.32-.4V.98c0-.29-.12-.4-.32-.4s-.33.11-.33.4z"/><path fill="currentColor" d="M0 4.53h14.02v1.04H0zM11.08 0h.63v.62h-.63zm.63 4V1h-.63v2.98z"/></svg>';
export var BPMNIO_IMG = BPMNIO_LOGO_SVG;
export var LOGO_STYLES = {
  verticalAlign: 'middle'
};
export var LINK_STYLES = {
  'color': '#404040'
};
var LIGHTBOX_STYLES = {
  'zIndex': '1001',
  'position': 'fixed',
  'top': '0',
  'left': '0',
  'right': '0',
  'bottom': '0'
};
var BACKDROP_STYLES = {
  'width': '100%',
  'height': '100%',
  'background': 'rgba(40,40,40,0.2)'
};
var NOTICE_STYLES = {
  'position': 'absolute',
  'left': '50%',
  'top': '40%',
  'transform': 'translate(-50%)',
  'width': '260px',
  'padding': '10px',
  'background': 'white',
  'boxShadow': '0 1px 4px rgba(0,0,0,0.3)',
  'fontFamily': 'Helvetica, Arial, sans-serif',
  'fontSize': '14px',
  'display': 'flex',
  'lineHeight': '1.3'
};
var LIGHTBOX_MARKUP = '<div class="bjs-powered-by-lightbox">' + '<div class="backdrop"></div>' + '<div class="notice">' + '<a href="https://bpmn.io" target="_blank" rel="noopener" class="link">' + BPMNIO_IMG + '</a>' + '<span>' + 'Web-based tooling for BPMN, DMN and forms ' + 'powered by <a href="https://bpmn.io" target="_blank" rel="noopener">bpmn.io</a>.' + '</span>' + '</div>' + '</div>';
var lightbox;
function createLightbox() {
  lightbox = domify(LIGHTBOX_MARKUP);
  assignStyle(lightbox, LIGHTBOX_STYLES);
  assignStyle(domQuery('svg', lightbox), LOGO_STYLES);
  assignStyle(domQuery('.backdrop', lightbox), BACKDROP_STYLES);
  assignStyle(domQuery('.notice', lightbox), NOTICE_STYLES);
  assignStyle(domQuery('.link', lightbox), LINK_STYLES, {
    'margin': '15px 20px 15px 10px',
    'alignSelf': 'center'
  });
}
export function open() {
  if (!lightbox) {
    createLightbox();
    domDelegate.bind(lightbox, '.backdrop', 'click', function (event) {
      document.body.removeChild(lightbox);
    });
  }
  document.body.appendChild(lightbox);
}
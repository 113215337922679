import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPaginatedResponse } from 'src/app/model/api-paginated-response.model';
import { ApiResponse } from 'src/app/model/apiresponse.model';
import { BusinessProcess } from 'src/app/model/business-process.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessProcessesService {

  constructor(private http: HttpClient) { }

  get(organizationId: string, businessProcessesId: string): Observable<ApiResponse<BusinessProcess | null>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/business-process/${businessProcessesId}`,
    );
  }

  list(organizationId: string): Observable<ApiResponse<BusinessProcess[] | undefined>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/job-title`
    );
  }

  getDiagramFile(organizationId: string, businessProcessesId: string): Observable<Blob> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/octet-stream');
    
    return this.http.get(
      `${environment.apiUrl}/${organizationId}/business-process/${businessProcessesId}/file`,
      { 
        headers:headers,
        responseType: 'blob'
      }
    );
  }

  paginatedList(organizationId: string, page: number, pageSize: number): Observable<ApiPaginatedResponse<BusinessProcess[] | null>> {
    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/business-process`,
      {
        params: {
          page: page,
          limit: pageSize,
          orderBy: 'name',
          asc: 1,
          'with[]': ['supportingAssets', 'supportingDocuments'],
          'withCount[]': ['supportingAssets', 'supportingDocuments']
        }
      }
    );
  }

  filteredPaginatedList( organizationId: string, page: number, pageSize: number, filter: string, filterValue: string): Observable<ApiPaginatedResponse<BusinessProcess[] | null>> {
    let params: { [Key: string]: any } = {
      page: page,
      limit: pageSize,
      orderBy: 'name',
      asc: 1,
      'with[]': ['supportingAssets', 'supportingDocuments'],
      'withCount[]': ['supportingAssets', 'supportingDocuments']
    };

    if (filterValue != null && filterValue !== '') {
      params['orderBy'] = filter;
      params['filterBy'] = filter;
      params['filter'] = filterValue;
    }

    return this.http.get<any>(
      `${environment.apiUrl}/${organizationId}/business-process`,
      {
        params: params
      }
    );
  }

  create(organizationId: string, businessProcess: BusinessProcess, file?: Blob): Observable<ApiResponse<BusinessProcess | null>> {
    const formData = new FormData();
    if (file != null) {
      formData.append('file', file);
    }

    Object.entries(businessProcess).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return this.http.post<any>(
      `${environment.apiUrl}/${organizationId}/business-process`,
      formData
    )
  }

  update(organizationId: string, businessProcess: BusinessProcess, file?: Blob): Observable<ApiResponse<BusinessProcess | null>> {
    const formData = new FormData();
    if (file != null) {
      formData.append('file', file);
      formData.append('_method', 'PUT');

      Object.entries(businessProcess).forEach(([key, value]) => {
        formData.append(key, value);
      });
  
      return this.http.post<any>(
        `${environment.apiUrl}/${organizationId}/business-process/${businessProcess.id}`,
        formData
      );
    } else {
      return this.http.put<any>(
        `${environment.apiUrl}/${organizationId}/business-process/${businessProcess.id}`,
        businessProcess
      );
    }
  }

  delete(organizationId: string, businessProcessesId: string): Observable<ApiResponse<BusinessProcess | null>> {
    return this.http.delete<any>(
      `${environment.apiUrl}/${organizationId}/business-process/${businessProcessesId}`,
    );
  }

  
}

import { config } from 'rxjs';

/**
 * This module is an append menu provider for the popup menu.
 */
export default function AssetPopupProvider(
    config,
    elementFactory,
    popupMenu,
    create,
    autoPlace,
    rules,
    mouse,
    translate,
    modeling
) {

  this._elementFactory = elementFactory;
  this._modeling = modeling;
  this._popupMenu = popupMenu;
  this._create = create;
  this._autoPlace = autoPlace;
  this._rules = rules;
  this._create = create;
  this._mouse = mouse;
  this._translate = translate;
  this._config = config;

  this.register();
}

AssetPopupProvider.$inject = [
    'config.selectAsset',
    'elementFactory',
    'popupMenu',
    'create',
    'autoPlace',
    'rules',
    'mouse',
    'translate',
    'modeling'
];

/**
 * Register append menu provider in the popup menu
 */
AssetPopupProvider.prototype.register = function() {
  this._popupMenu.registerProvider('bpmn-select-asset', this);
};

AssetPopupProvider.prototype.open = function(element) {
    this._popupMenu.open.call(element, position, options);
}

/**
 * Gets the append options for the given element as menu entries
 *
 * @param {djs.model.Base} element
 *
 * @return {Array<Object>} a list of menu entry items
 */
AssetPopupProvider.prototype.getPopupMenuEntries = function(element) {
  
  const rules = this._rules;
  const translate = this._translate;
  const entries = {};

  this._config.assets.forEach(asset => {
    entries[asset.id] = {
      label: asset.name,
      description: asset.description,
      action: this._createEntryAction(element, asset)
    };
  });
  
  return entries;

};

/**
 * Create an action for a given target.
 *
 * @param {djs.model.Base} element
 *
 * @return {Object}
 */
AssetPopupProvider.prototype._createEntryAction = function(element, asset) {
  const elementFactory = this._elementFactory;
  const autoPlace = this._autoPlace;
  const create = this._create;
  const mouse = this._mouse;

  const autoPlaceElement = () => {
    const newElement = elementFactory.createShape({ 
      type: 'aa:Asset', 
      params: {
        asset_id: asset.id,
        asset_name: asset.name
      }
    });
    //create.start(null, newElement, element);
    if (newElement) {
      autoPlace.append(element, newElement);
    }
    
  };

  return {
    click: autoPlaceElement
  };

};
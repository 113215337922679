/**
 * This file contains source code adapted from Snap.svg (licensed Apache-2.0).
 *
 * @see https://github.com/adobe-webplatform/Snap.svg/blob/master/src/path.js
 */

/* eslint no-fallthrough: "off" */

var p2s = /,?([a-z]),?/gi,
  toFloat = parseFloat,
  math = Math,
  PI = math.PI,
  mmin = math.min,
  mmax = math.max,
  pow = math.pow,
  abs = math.abs,
  pathCommand = /([a-z])[\s,]*((-?\d*\.?\d*(?:e[-+]?\d+)?[\s]*,?[\s]*)+)/ig,
  pathValues = /(-?\d*\.?\d*(?:e[-+]?\d+)?)[\s]*,?[\s]*/ig;
var isArray = Array.isArray || function (o) {
  return o instanceof Array;
};
function hasProperty(obj, property) {
  return Object.prototype.hasOwnProperty.call(obj, property);
}
function clone(obj) {
  if (typeof obj == 'function' || Object(obj) !== obj) {
    return obj;
  }
  var res = new obj.constructor();
  for (var key in obj) {
    if (hasProperty(obj, key)) {
      res[key] = clone(obj[key]);
    }
  }
  return res;
}
function repush(array, item) {
  for (var i = 0, ii = array.length; i < ii; i++) if (array[i] === item) {
    return array.push(array.splice(i, 1)[0]);
  }
}
function cacher(f) {
  function newf() {
    var arg = Array.prototype.slice.call(arguments, 0),
      args = arg.join('\u2400'),
      cache = newf.cache = newf.cache || {},
      count = newf.count = newf.count || [];
    if (hasProperty(cache, args)) {
      repush(count, args);
      return cache[args];
    }
    count.length >= 1e3 && delete cache[count.shift()];
    count.push(args);
    cache[args] = f(...arguments);
    return cache[args];
  }
  return newf;
}
function parsePathString(pathString) {
  if (!pathString) {
    return null;
  }
  var pth = paths(pathString);
  if (pth.arr) {
    return clone(pth.arr);
  }
  var paramCounts = {
      a: 7,
      c: 6,
      h: 1,
      l: 2,
      m: 2,
      q: 4,
      s: 4,
      t: 2,
      v: 1,
      z: 0
    },
    data = [];
  if (isArray(pathString) && isArray(pathString[0])) {
    // rough assumption
    data = clone(pathString);
  }
  if (!data.length) {
    String(pathString).replace(pathCommand, function (a, b, c) {
      var params = [],
        name = b.toLowerCase();
      c.replace(pathValues, function (a, b) {
        b && params.push(+b);
      });
      if (name == 'm' && params.length > 2) {
        data.push([b, ...params.splice(0, 2)]);
        name = 'l';
        b = b == 'm' ? 'l' : 'L';
      }
      while (params.length >= paramCounts[name]) {
        data.push([b, ...params.splice(0, paramCounts[name])]);
        if (!paramCounts[name]) {
          break;
        }
      }
    });
  }
  data.toString = paths.toString;
  pth.arr = clone(data);
  return data;
}
function paths(ps) {
  var p = paths.ps = paths.ps || {};
  if (p[ps]) {
    p[ps].sleep = 100;
  } else {
    p[ps] = {
      sleep: 100
    };
  }
  setTimeout(function () {
    for (var key in p) {
      if (hasProperty(p, key) && key != ps) {
        p[key].sleep--;
        !p[key].sleep && delete p[key];
      }
    }
  });
  return p[ps];
}
function rectBBox(x, y, width, height) {
  if (arguments.length === 1) {
    y = x.y;
    width = x.width;
    height = x.height;
    x = x.x;
  }
  return {
    x: x,
    y: y,
    width: width,
    height: height,
    x2: x + width,
    y2: y + height
  };
}
function pathToString() {
  return this.join(',').replace(p2s, '$1');
}
function pathClone(pathArray) {
  var res = clone(pathArray);
  res.toString = pathToString;
  return res;
}
function findDotsAtSegment(p1x, p1y, c1x, c1y, c2x, c2y, p2x, p2y, t) {
  var t1 = 1 - t,
    t13 = pow(t1, 3),
    t12 = pow(t1, 2),
    t2 = t * t,
    t3 = t2 * t,
    x = t13 * p1x + t12 * 3 * t * c1x + t1 * 3 * t * t * c2x + t3 * p2x,
    y = t13 * p1y + t12 * 3 * t * c1y + t1 * 3 * t * t * c2y + t3 * p2y;
  return {
    x: fixError(x),
    y: fixError(y)
  };
}
function bezierBBox(points) {
  var bbox = curveBBox(...points);
  return rectBBox(bbox.x0, bbox.y0, bbox.x1 - bbox.x0, bbox.y1 - bbox.y0);
}
function isPointInsideBBox(bbox, x, y) {
  return x >= bbox.x && x <= bbox.x + bbox.width && y >= bbox.y && y <= bbox.y + bbox.height;
}
function isBBoxIntersect(bbox1, bbox2) {
  bbox1 = rectBBox(bbox1);
  bbox2 = rectBBox(bbox2);
  return isPointInsideBBox(bbox2, bbox1.x, bbox1.y) || isPointInsideBBox(bbox2, bbox1.x2, bbox1.y) || isPointInsideBBox(bbox2, bbox1.x, bbox1.y2) || isPointInsideBBox(bbox2, bbox1.x2, bbox1.y2) || isPointInsideBBox(bbox1, bbox2.x, bbox2.y) || isPointInsideBBox(bbox1, bbox2.x2, bbox2.y) || isPointInsideBBox(bbox1, bbox2.x, bbox2.y2) || isPointInsideBBox(bbox1, bbox2.x2, bbox2.y2) || (bbox1.x < bbox2.x2 && bbox1.x > bbox2.x || bbox2.x < bbox1.x2 && bbox2.x > bbox1.x) && (bbox1.y < bbox2.y2 && bbox1.y > bbox2.y || bbox2.y < bbox1.y2 && bbox2.y > bbox1.y);
}
function base3(t, p1, p2, p3, p4) {
  var t1 = -3 * p1 + 9 * p2 - 9 * p3 + 3 * p4,
    t2 = t * t1 + 6 * p1 - 12 * p2 + 6 * p3;
  return t * t2 - 3 * p1 + 3 * p2;
}
function bezlen(x1, y1, x2, y2, x3, y3, x4, y4, z) {
  if (z == null) {
    z = 1;
  }
  z = z > 1 ? 1 : z < 0 ? 0 : z;
  var z2 = z / 2,
    n = 12,
    Tvalues = [-.1252, .1252, -.3678, .3678, -.5873, .5873, -.7699, .7699, -.9041, .9041, -.9816, .9816],
    Cvalues = [0.2491, 0.2491, 0.2335, 0.2335, 0.2032, 0.2032, 0.1601, 0.1601, 0.1069, 0.1069, 0.0472, 0.0472],
    sum = 0;
  for (var i = 0; i < n; i++) {
    var ct = z2 * Tvalues[i] + z2,
      xbase = base3(ct, x1, x2, x3, x4),
      ybase = base3(ct, y1, y2, y3, y4),
      comb = xbase * xbase + ybase * ybase;
    sum += Cvalues[i] * math.sqrt(comb);
  }
  return z2 * sum;
}
function intersectLines(x1, y1, x2, y2, x3, y3, x4, y4) {
  if (mmax(x1, x2) < mmin(x3, x4) || mmin(x1, x2) > mmax(x3, x4) || mmax(y1, y2) < mmin(y3, y4) || mmin(y1, y2) > mmax(y3, y4)) {
    return;
  }
  var nx = (x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4),
    ny = (x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4),
    denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  if (!denominator) {
    return;
  }
  var px = fixError(nx / denominator),
    py = fixError(ny / denominator),
    px2 = +px.toFixed(2),
    py2 = +py.toFixed(2);
  if (px2 < +mmin(x1, x2).toFixed(2) || px2 > +mmax(x1, x2).toFixed(2) || px2 < +mmin(x3, x4).toFixed(2) || px2 > +mmax(x3, x4).toFixed(2) || py2 < +mmin(y1, y2).toFixed(2) || py2 > +mmax(y1, y2).toFixed(2) || py2 < +mmin(y3, y4).toFixed(2) || py2 > +mmax(y3, y4).toFixed(2)) {
    return;
  }
  return {
    x: px,
    y: py
  };
}
function fixError(number) {
  return Math.round(number * 100000000000) / 100000000000;
}
function findBezierIntersections(bez1, bez2, justCount) {
  var bbox1 = bezierBBox(bez1),
    bbox2 = bezierBBox(bez2);
  if (!isBBoxIntersect(bbox1, bbox2)) {
    return justCount ? 0 : [];
  }

  // As an optimization, lines will have only 1 segment

  var l1 = bezlen(...bez1),
    l2 = bezlen(...bez2),
    n1 = isLine(bez1) ? 1 : ~~(l1 / 5) || 1,
    n2 = isLine(bez2) ? 1 : ~~(l2 / 5) || 1,
    dots1 = [],
    dots2 = [],
    xy = {},
    res = justCount ? 0 : [];
  for (var i = 0; i < n1 + 1; i++) {
    var p = findDotsAtSegment(...bez1, i / n1);
    dots1.push({
      x: p.x,
      y: p.y,
      t: i / n1
    });
  }
  for (i = 0; i < n2 + 1; i++) {
    p = findDotsAtSegment(...bez2, i / n2);
    dots2.push({
      x: p.x,
      y: p.y,
      t: i / n2
    });
  }
  for (i = 0; i < n1; i++) {
    for (var j = 0; j < n2; j++) {
      var di = dots1[i],
        di1 = dots1[i + 1],
        dj = dots2[j],
        dj1 = dots2[j + 1],
        ci = abs(di1.x - di.x) < .01 ? 'y' : 'x',
        cj = abs(dj1.x - dj.x) < .01 ? 'y' : 'x',
        is = intersectLines(di.x, di.y, di1.x, di1.y, dj.x, dj.y, dj1.x, dj1.y),
        key;
      if (is) {
        key = is.x.toFixed(9) + '#' + is.y.toFixed(9);
        if (xy[key]) {
          continue;
        }
        xy[key] = true;
        var t1 = di.t + abs((is[ci] - di[ci]) / (di1[ci] - di[ci])) * (di1.t - di.t),
          t2 = dj.t + abs((is[cj] - dj[cj]) / (dj1[cj] - dj[cj])) * (dj1.t - dj.t);
        if (t1 >= 0 && t1 <= 1 && t2 >= 0 && t2 <= 1) {
          if (justCount) {
            res++;
          } else {
            res.push({
              x: is.x,
              y: is.y,
              t1: t1,
              t2: t2
            });
          }
        }
      }
    }
  }
  return res;
}

/**
 * Find or counts the intersections between two SVG paths.
 *
 * Returns a number in counting mode and a list of intersections otherwise.
 *
 * A single intersection entry contains the intersection coordinates (x, y)
 * as well as additional information regarding the intersecting segments
 * on each path (segment1, segment2) and the relative location of the
 * intersection on these segments (t1, t2).
 *
 * The path may be an SVG path string or a list of path components
 * such as `[ [ 'M', 0, 10 ], [ 'L', 20, 0 ] ]`.
 *
 * @example
 *
 * var intersections = findPathIntersections(
 *   'M0,0L100,100',
 *   [ [ 'M', 0, 100 ], [ 'L', 100, 0 ] ]
 * );
 *
 * // intersections = [
 * //   { x: 50, y: 50, segment1: 1, segment2: 1, t1: 0.5, t2: 0.5 }
 * // ]
 *
 * @param {String|Array<PathDef>} path1
 * @param {String|Array<PathDef>} path2
 * @param {Boolean} [justCount=false]
 *
 * @return {Array<Intersection>|Number}
 */
export default function findPathIntersections(path1, path2, justCount) {
  path1 = pathToCurve(path1);
  path2 = pathToCurve(path2);
  var x1,
    y1,
    x2,
    y2,
    x1m,
    y1m,
    x2m,
    y2m,
    bez1,
    bez2,
    res = justCount ? 0 : [];
  for (var i = 0, ii = path1.length; i < ii; i++) {
    var pi = path1[i];
    if (pi[0] == 'M') {
      x1 = x1m = pi[1];
      y1 = y1m = pi[2];
    } else {
      if (pi[0] == 'C') {
        bez1 = [x1, y1, ...pi.slice(1)];
        x1 = bez1[6];
        y1 = bez1[7];
      } else {
        bez1 = [x1, y1, x1, y1, x1m, y1m, x1m, y1m];
        x1 = x1m;
        y1 = y1m;
      }
      for (var j = 0, jj = path2.length; j < jj; j++) {
        var pj = path2[j];
        if (pj[0] == 'M') {
          x2 = x2m = pj[1];
          y2 = y2m = pj[2];
        } else {
          if (pj[0] == 'C') {
            bez2 = [x2, y2, ...pj.slice(1)];
            x2 = bez2[6];
            y2 = bez2[7];
          } else {
            bez2 = [x2, y2, x2, y2, x2m, y2m, x2m, y2m];
            x2 = x2m;
            y2 = y2m;
          }
          var intr = findBezierIntersections(bez1, bez2, justCount);
          if (justCount) {
            res += intr;
          } else {
            for (var k = 0, kk = intr.length; k < kk; k++) {
              intr[k].segment1 = i;
              intr[k].segment2 = j;
              intr[k].bez1 = bez1;
              intr[k].bez2 = bez2;
            }
            res = res.concat(intr);
          }
        }
      }
    }
  }
  return res;
}
function pathToAbsolute(pathArray) {
  var pth = paths(pathArray);
  if (pth.abs) {
    return pathClone(pth.abs);
  }
  if (!isArray(pathArray) || !isArray(pathArray && pathArray[0])) {
    // rough assumption
    pathArray = parsePathString(pathArray);
  }
  if (!pathArray || !pathArray.length) {
    return [['M', 0, 0]];
  }
  var res = [],
    x = 0,
    y = 0,
    mx = 0,
    my = 0,
    start = 0,
    pa0;
  if (pathArray[0][0] == 'M') {
    x = +pathArray[0][1];
    y = +pathArray[0][2];
    mx = x;
    my = y;
    start++;
    res[0] = ['M', x, y];
  }
  for (var r, pa, i = start, ii = pathArray.length; i < ii; i++) {
    res.push(r = []);
    pa = pathArray[i];
    pa0 = pa[0];
    if (pa0 != pa0.toUpperCase()) {
      r[0] = pa0.toUpperCase();
      switch (r[0]) {
        case 'A':
          r[1] = pa[1];
          r[2] = pa[2];
          r[3] = pa[3];
          r[4] = pa[4];
          r[5] = pa[5];
          r[6] = +pa[6] + x;
          r[7] = +pa[7] + y;
          break;
        case 'V':
          r[1] = +pa[1] + y;
          break;
        case 'H':
          r[1] = +pa[1] + x;
          break;
        case 'M':
          mx = +pa[1] + x;
          my = +pa[2] + y;
        default:
          for (var j = 1, jj = pa.length; j < jj; j++) {
            r[j] = +pa[j] + (j % 2 ? x : y);
          }
      }
    } else {
      for (var k = 0, kk = pa.length; k < kk; k++) {
        r[k] = pa[k];
      }
    }
    pa0 = pa0.toUpperCase();
    switch (r[0]) {
      case 'Z':
        x = +mx;
        y = +my;
        break;
      case 'H':
        x = r[1];
        break;
      case 'V':
        y = r[1];
        break;
      case 'M':
        mx = r[r.length - 2];
        my = r[r.length - 1];
      default:
        x = r[r.length - 2];
        y = r[r.length - 1];
    }
  }
  res.toString = pathToString;
  pth.abs = pathClone(res);
  return res;
}
function isLine(bez) {
  return bez[0] === bez[2] && bez[1] === bez[3] && bez[4] === bez[6] && bez[5] === bez[7];
}
function lineToCurve(x1, y1, x2, y2) {
  return [x1, y1, x2, y2, x2, y2];
}
function qubicToCurve(x1, y1, ax, ay, x2, y2) {
  var _13 = 1 / 3,
    _23 = 2 / 3;
  return [_13 * x1 + _23 * ax, _13 * y1 + _23 * ay, _13 * x2 + _23 * ax, _13 * y2 + _23 * ay, x2, y2];
}
function arcToCurve(x1, y1, rx, ry, angle, large_arc_flag, sweep_flag, x2, y2, recursive) {
  // for more information of where this math came from visit:
  // http://www.w3.org/TR/SVG11/implnote.html#ArcImplementationNotes
  var _120 = PI * 120 / 180,
    rad = PI / 180 * (+angle || 0),
    res = [],
    xy,
    rotate = cacher(function (x, y, rad) {
      var X = x * math.cos(rad) - y * math.sin(rad),
        Y = x * math.sin(rad) + y * math.cos(rad);
      return {
        x: X,
        y: Y
      };
    });
  if (!recursive) {
    xy = rotate(x1, y1, -rad);
    x1 = xy.x;
    y1 = xy.y;
    xy = rotate(x2, y2, -rad);
    x2 = xy.x;
    y2 = xy.y;
    var x = (x1 - x2) / 2,
      y = (y1 - y2) / 2;
    var h = x * x / (rx * rx) + y * y / (ry * ry);
    if (h > 1) {
      h = math.sqrt(h);
      rx = h * rx;
      ry = h * ry;
    }
    var rx2 = rx * rx,
      ry2 = ry * ry,
      k = (large_arc_flag == sweep_flag ? -1 : 1) * math.sqrt(abs((rx2 * ry2 - rx2 * y * y - ry2 * x * x) / (rx2 * y * y + ry2 * x * x))),
      cx = k * rx * y / ry + (x1 + x2) / 2,
      cy = k * -ry * x / rx + (y1 + y2) / 2,
      f1 = math.asin(((y1 - cy) / ry).toFixed(9)),
      f2 = math.asin(((y2 - cy) / ry).toFixed(9));
    f1 = x1 < cx ? PI - f1 : f1;
    f2 = x2 < cx ? PI - f2 : f2;
    f1 < 0 && (f1 = PI * 2 + f1);
    f2 < 0 && (f2 = PI * 2 + f2);
    if (sweep_flag && f1 > f2) {
      f1 = f1 - PI * 2;
    }
    if (!sweep_flag && f2 > f1) {
      f2 = f2 - PI * 2;
    }
  } else {
    f1 = recursive[0];
    f2 = recursive[1];
    cx = recursive[2];
    cy = recursive[3];
  }
  var df = f2 - f1;
  if (abs(df) > _120) {
    var f2old = f2,
      x2old = x2,
      y2old = y2;
    f2 = f1 + _120 * (sweep_flag && f2 > f1 ? 1 : -1);
    x2 = cx + rx * math.cos(f2);
    y2 = cy + ry * math.sin(f2);
    res = arcToCurve(x2, y2, rx, ry, angle, 0, sweep_flag, x2old, y2old, [f2, f2old, cx, cy]);
  }
  df = f2 - f1;
  var c1 = math.cos(f1),
    s1 = math.sin(f1),
    c2 = math.cos(f2),
    s2 = math.sin(f2),
    t = math.tan(df / 4),
    hx = 4 / 3 * rx * t,
    hy = 4 / 3 * ry * t,
    m1 = [x1, y1],
    m2 = [x1 + hx * s1, y1 - hy * c1],
    m3 = [x2 + hx * s2, y2 - hy * c2],
    m4 = [x2, y2];
  m2[0] = 2 * m1[0] - m2[0];
  m2[1] = 2 * m1[1] - m2[1];
  if (recursive) {
    return [m2, m3, m4].concat(res);
  } else {
    res = [m2, m3, m4].concat(res).join().split(',');
    var newres = [];
    for (var i = 0, ii = res.length; i < ii; i++) {
      newres[i] = i % 2 ? rotate(res[i - 1], res[i], rad).y : rotate(res[i], res[i + 1], rad).x;
    }
    return newres;
  }
}

// Returns bounding box of cubic bezier curve.
// Source: http://blog.hackers-cafe.net/2009/06/how-to-calculate-bezier-curves-bounding.html
// Original version: NISHIO Hirokazu
// Modifications: https://github.com/timo22345
function curveBBox(x0, y0, x1, y1, x2, y2, x3, y3) {
  var tvalues = [],
    bounds = [[], []],
    a,
    b,
    c,
    t,
    t1,
    t2,
    b2ac,
    sqrtb2ac;
  for (var i = 0; i < 2; ++i) {
    if (i == 0) {
      b = 6 * x0 - 12 * x1 + 6 * x2;
      a = -3 * x0 + 9 * x1 - 9 * x2 + 3 * x3;
      c = 3 * x1 - 3 * x0;
    } else {
      b = 6 * y0 - 12 * y1 + 6 * y2;
      a = -3 * y0 + 9 * y1 - 9 * y2 + 3 * y3;
      c = 3 * y1 - 3 * y0;
    }
    if (abs(a) < 1e-12) {
      if (abs(b) < 1e-12) {
        continue;
      }
      t = -c / b;
      if (0 < t && t < 1) {
        tvalues.push(t);
      }
      continue;
    }
    b2ac = b * b - 4 * c * a;
    sqrtb2ac = math.sqrt(b2ac);
    if (b2ac < 0) {
      continue;
    }
    t1 = (-b + sqrtb2ac) / (2 * a);
    if (0 < t1 && t1 < 1) {
      tvalues.push(t1);
    }
    t2 = (-b - sqrtb2ac) / (2 * a);
    if (0 < t2 && t2 < 1) {
      tvalues.push(t2);
    }
  }
  var j = tvalues.length,
    jlen = j,
    mt;
  while (j--) {
    t = tvalues[j];
    mt = 1 - t;
    bounds[0][j] = mt * mt * mt * x0 + 3 * mt * mt * t * x1 + 3 * mt * t * t * x2 + t * t * t * x3;
    bounds[1][j] = mt * mt * mt * y0 + 3 * mt * mt * t * y1 + 3 * mt * t * t * y2 + t * t * t * y3;
  }
  bounds[0][jlen] = x0;
  bounds[1][jlen] = y0;
  bounds[0][jlen + 1] = x3;
  bounds[1][jlen + 1] = y3;
  bounds[0].length = bounds[1].length = jlen + 2;
  return {
    x0: mmin(...bounds[0]),
    y0: mmin(...bounds[1]),
    x1: mmax(...bounds[0]),
    y1: mmax(...bounds[1])
  };
}
function pathToCurve(path) {
  var pth = paths(path);

  // return cached curve, if existing
  if (pth.curve) {
    return pathClone(pth.curve);
  }
  var curvedPath = pathToAbsolute(path),
    attrs = {
      x: 0,
      y: 0,
      bx: 0,
      by: 0,
      X: 0,
      Y: 0,
      qx: null,
      qy: null
    },
    processPath = function (path, d, pathCommand) {
      var nx, ny;
      if (!path) {
        return ['C', d.x, d.y, d.x, d.y, d.x, d.y];
      }
      !(path[0] in {
        T: 1,
        Q: 1
      }) && (d.qx = d.qy = null);
      switch (path[0]) {
        case 'M':
          d.X = path[1];
          d.Y = path[2];
          break;
        case 'A':
          path = ['C', ...arcToCurve(d.x, d.y, ...path.slice(1))];
          break;
        case 'S':
          if (pathCommand == 'C' || pathCommand == 'S') {
            // In 'S' case we have to take into account, if the previous command is C/S.
            nx = d.x * 2 - d.bx;

            // And reflect the previous
            ny = d.y * 2 - d.by;

            // command's control point relative to the current point.
          } else {
            // or some else or nothing
            nx = d.x;
            ny = d.y;
          }
          path = ['C', nx, ny, ...path.slice(1)];
          break;
        case 'T':
          if (pathCommand == 'Q' || pathCommand == 'T') {
            // In 'T' case we have to take into account, if the previous command is Q/T.
            d.qx = d.x * 2 - d.qx;

            // And make a reflection similar
            d.qy = d.y * 2 - d.qy;

            // to case 'S'.
          } else {
            // or something else or nothing
            d.qx = d.x;
            d.qy = d.y;
          }
          path = ['C', ...qubicToCurve(d.x, d.y, d.qx, d.qy, path[1], path[2])];
          break;
        case 'Q':
          d.qx = path[1];
          d.qy = path[2];
          path = ['C', ...qubicToCurve(d.x, d.y, path[1], path[2], path[3], path[4])];
          break;
        case 'L':
          path = ['C', ...lineToCurve(d.x, d.y, path[1], path[2])];
          break;
        case 'H':
          path = ['C', ...lineToCurve(d.x, d.y, path[1], d.y)];
          break;
        case 'V':
          path = ['C', ...lineToCurve(d.x, d.y, d.x, path[1])];
          break;
        case 'Z':
          path = ['C', ...lineToCurve(d.x, d.y, d.X, d.Y)];
          break;
      }
      return path;
    },
    fixArc = function (pp, i) {
      if (pp[i].length > 7) {
        pp[i].shift();
        var pi = pp[i];
        while (pi.length) {
          pathCommands[i] = 'A'; // if created multiple C:s, their original seg is saved
          pp.splice(i++, 0, ['C', ...pi.splice(0, 6)]);
        }
        pp.splice(i, 1);
        ii = curvedPath.length;
      }
    },
    pathCommands = [],
    // path commands of original path p
    pfirst = '',
    // temporary holder for original path command
    pathCommand = ''; // holder for previous path command of original path

  for (var i = 0, ii = curvedPath.length; i < ii; i++) {
    curvedPath[i] && (pfirst = curvedPath[i][0]); // save current path command

    if (pfirst != 'C')
      // C is not saved yet, because it may be result of conversion
      {
        pathCommands[i] = pfirst; // Save current path command
        i && (pathCommand = pathCommands[i - 1]); // Get previous path command pathCommand
      }
    curvedPath[i] = processPath(curvedPath[i], attrs, pathCommand); // Previous path command is inputted to processPath

    if (pathCommands[i] != 'A' && pfirst == 'C') pathCommands[i] = 'C'; // A is the only command
    // which may produce multiple C:s
    // so we have to make sure that C is also C in original path

    fixArc(curvedPath, i); // fixArc adds also the right amount of A:s to pathCommands

    var seg = curvedPath[i],
      seglen = seg.length;
    attrs.x = seg[seglen - 2];
    attrs.y = seg[seglen - 1];
    attrs.bx = toFloat(seg[seglen - 4]) || attrs.x;
    attrs.by = toFloat(seg[seglen - 3]) || attrs.y;
  }

  // cache curve
  pth.curve = pathClone(curvedPath);
  return curvedPath;
}
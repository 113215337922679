import { create } from 'tiny-svg';


export class SvgUtil {
    static async loadSvgImage(filePath) {
        const response = await fetch(filePath);
        if (!response.ok) {
            throw new Error('failed to fetch svg image');
        }

        return response.text();
    }

    static changeSvgColor(svgContent, color) {
        // Create a DOM parser to parse the SVG content
        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(svgContent, 'image/svg+xml');
        const svgElement = svgDocument.documentElement;

        // Change the color of all paths (or other elements) in the SVG
        const elementsToColor = svgElement.querySelectorAll('path, rect, circle, polygon, line, polyline, ellipse');
        elementsToColor.forEach(element => {
            element.setAttribute('fill', color);
        });

        // Serialize the updated SVG back to a string
        const serializer = new XMLSerializer();
        return serializer.serializeToString(svgElement);
    }

    static renderSvgFromString(svgString) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = svgString;

        const svgElement = tempDiv.querySelector('svg');

        const tinySvgElement = create('svg');

        for (const attr of svgElement.attributes) {
            tinySvgElement.setAttribute(attr.name, attr.value);
        }

        while (svgElement.firstChild) {
            tinySvgElement.appendChild(svgElement.firstChild);
        }

        return tinySvgElement;
    }
}
import inherits from 'inherits-browser';
import { getFlowNodePosition, getNewShapePosition } from 'bpmn-js/lib/features/auto-place/BpmnAutoPlaceUtil';
import { getMid } from 'diagram-js/lib/layout/LayoutUtil';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import GridSnappingAutoPlaceBehavior from 'bpmn-js/lib/features/grid-snapping/behavior/GridSnappingAutoPlaceBehavior';

var HIGH_PRIORITY = 2000;

/**
 * @param {EventBus} eventBus
 * @param {GridSnapping} gridSnapping
 */
export default function CustomGridSnappingAutoPlaceBehavior(eventBus, gridSnapping) {
  eventBus.on('autoPlace', HIGH_PRIORITY, function(context) {
    var source = context.source,
        sourceMid = getMid(source),
        shape = context.shape;

    var position = getNewShapePosition(source, shape);

    if (shape.type === 'aa:Asset' || shape.type === 'aa:Document') {
        position = getFlowNodePosition(source, shape);
    }

    [ 'x', 'y' ].forEach(function(axis) {
      var options = {};

      // do not snap if x/y equal
      if (position[ axis ] === sourceMid[ axis ]) {
        return;
      }

      if (position[ axis ] > sourceMid[ axis ]) {
        options.min = position[ axis ];
      } else {
        options.max = position[ axis ];
      }

      if (is(shape, 'bpmn:TextAnnotation')) {

        if (isHorizontal(axis)) {
          options.offset = -shape.width / 2;
        } else {
          options.offset = -shape.height / 2;
        }

      }

      position[ axis ] = gridSnapping.snapValue(position[ axis ], options);

    });

    // must be returned to be considered by auto place
    return position;
  });
}

inherits(CustomGridSnappingAutoPlaceBehavior, GridSnappingAutoPlaceBehavior);

CustomGridSnappingAutoPlaceBehavior.$inject = [
  'eventBus',
  'gridSnapping'
];

// helpers //////////

/**
 * @param {Axis} axis
 *
 * @return {boolean}
 */
function isHorizontal(axis) {
  return axis === 'x';
}
import inherits from 'inherits-browser';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import CommandInterceptor from 'diagram-js/lib/command/CommandInterceptor';

/**
 * @typedef {import('diagram-js/lib/core/EventBus').default} EventBus
 */

export default function AssetBehavior(eventBus, modeling) {

  CommandInterceptor.call(this, eventBus);

  // On Append, TextAnnotations will be created on the Root.
  // The default for connections will create the connection in the parent of
  // the source element, so we overwrite the parent here.
  this.preExecute([ 'connection.create', 'connection.reconnect', 'connection.reconnectStart' ], function(context) {
    const { target, source, connection } = context;
    if (is(target, 'aa:Asset') || is(source, 'aa:Asset')) {
      if (connection.type != 'bpmn:Association') {
        connection.type = 'bpmn:Association';
      }
    }

    return;
  }, true);

  this.preExecute([ 'shape.create', 'shape.resize', 'elements.move' ], function(context) {
    const shapes = context.shapes || [ context.shape ];

    if (shapes.length === 1 && is(shapes[0], 'aa:Asset')) {
      context.hints = context.hints || {};

      context.hints.autoResize = false;
    }
  }, true);
}

inherits(AssetBehavior, CommandInterceptor);

AssetBehavior.$inject = [
  'eventBus',
  'modeling'
];